
    @media print {


         ul,li {
            page-break-inside: avoid!important; 
            break-inside: avoid!important; 
             /* white-space: nowrap; */
             
        };
        tr  { 
            display: table-row-group;
            page-break-inside:avoid; 
            page-break-after:auto;
            }

        /* td   { 
            display: table-cell;
            page-break-inside:avoid; 
            page-break-after:auto;
            }
        .table {
            page-break-inside: avoid!important; 
            break-inside: avoid!important; 
        }
        table >tr> td {
            
            page-break-inside: avoid!important; 
            break-inside: avoid!important; 
        }
         td {
            page-break-inside:avoid;
            background: "red"
          } */
    }
